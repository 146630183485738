import React, { useState } from 'react'
import Layout from '../../components/Layout'
import Drop from '../../components/NewDropdown'
import { graphql } from 'gatsby'

function DevotionalHymns({ data }) {
  let value = 0

  const [download, setIsDownloaded] = useState({
    morningHymns: false,
    eveningHymns: false,
    loadsDayHymns: false,
  })
  return (
    <Layout>
      <div className="px-16 py-4 bg-light-grey" style={{ minHeight: '85vh' }}>
        <h2 className="mb-8 font-bold text-center" id="errorDiv">
          Devotional Hymns
        </h2>
        <div className="flex justify-center">
          <div className="max-w-6xl text-justify space-y-4 leading-8">
            <p>
              Karen Neu has compiled a collection of hymn texts that are
              well-suited to devotional use. The hymns are presented without
              music in order to encourage the reader to stop and meditate on a
              line, or even a word, of the text.
            </p>
            <p>
              The collection is divided into categories: Morning Hymns; Evening
              Hymns; Hymns for the Lord’s Day; and, it is hoped, additional
              categories to be added in the future. Each set is available,
              without charge, as a .zip file, which on most computers can be
              “unzipped” by double-clicking on the file name. This will yield a
              collection of .pdf files, each containing a separate hymn. (If you
              prefer .docx files, please email{' '}
              <a href="mailto:david@Neusong.com">david@NeuSong.com</a>.)
            </p>
            <p>
              Occasionally an old English word, now unfamiliar to us, is used.
              For this reason, a very short glossary of such words is included
              with each set.
            </p>
            <div className="mt-4" style={{ fontSize: '1.2em' }}>
              Please indicate which of the sets you would like to download:{' '}
              <Drop header="Select Sets">
                <form
                  onSubmit={(e) => {
                    let publicURLs = []

                    if (download.morningHymns) {
                      publicURLs.push(data.MorningHymns.publicURL)
                    }
                    if (download.eveningHymns) {
                      publicURLs.push(data.EveningHymns.publicURL)
                    }
                    if (download.loadsDayHymns) {
                      publicURLs.push(data.LordsDayHymns.publicURL)
                    }

                    let link = document.createElement('a')

                    publicURLs.map((publicURL) => {
                      link.setAttribute('href', publicURL)
                      link.setAttribute('download', '')
                      link.click()
                    })

                    value = value + 1

                    if (
                      value === 1 &&
                      !download.morningHymns &&
                      !download.eveningHymns &&
                      !download.loadsDayHymns
                    ) {
                      let errorDiv = document.querySelector('#errorDiv')

                      let error = document.createElement('p')

                      error.className = 'text-lg text-red-400'
                      error.innerText =
                        'You must select at least one zip file to download'

                      errorDiv.appendChild(error)

                      setTimeout(() => {
                        errorDiv.removeChild(error)
                      }, 3000)
                      setTimeout(() => {
                        value = 0
                        console.log(value)
                      }, 3000)
                    }

                    e.preventDefault()
                  }}
                  className="flex flex-col justify-center p-2"
                >
                  <div>
                    <input
                      id="1"
                      type="checkbox"
                      className="w-4 h-4 p-2 form-checkbox"
                      onClick={() =>
                        setIsDownloaded({
                          morningHymns: !download.morningHymns,
                          eveningHymns: download.eveningHymns,
                          loadsDayHymns: download.loadsDayHymns,
                        })
                      }
                    />
                    <label className="mb-1 ml-4" htmlFor="1">
                      Morning Hymns
                    </label>
                  </div>
                  <div>
                    <input
                      id="2"
                      type="checkbox"
                      className="w-4 h-4 p-2 form-checkbox"
                      onClick={() =>
                        setIsDownloaded({
                          morningHymns: download.morningHymns,
                          eveningHymns: !download.eveningHymns,
                          loadsDayHymns: download.loadsDayHymns,
                        })
                      }
                    />
                    <label className="mb-1 ml-4" htmlFor="2">
                      Evening Hymns
                    </label>
                  </div>
                  <div>
                    <input
                      id="3"
                      type="checkbox"
                      className="w-4 h-4 p-2 form-checkbox"
                      onClick={() =>
                        setIsDownloaded({
                          morningHymns: download.morningHymns,
                          eveningHymns: download.eveningHymns,
                          loadsDayHymns: !download.loadsDayHymns,
                        })
                      }
                    />
                    <label className="mb-1 ml-4" htmlFor="3">
                      Lord's Day Hymns
                    </label>
                  </div>
                  <button
                    className="p-2 mt-3 bg-blue-200 rounded hover:text-gray-700 transform focus:outline-none hover:bg-blue-300 transition duration-300 ease-out"
                    type="submit"
                  >
                    Download
                  </button>
                </form>
              </Drop>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
  query DevotionalHymnsQuery {
    LordsDayHymns: file(relativePath: { eq: "LordsDayHymns.zip" }) {
      publicURL
    }
    MorningHymns: file(relativePath: { eq: "MorningHymns.zip" }) {
      publicURL
    }
    EveningHymns: file(relativePath: { eq: "EveningHymns.zip" }) {
      publicURL
    }
  }
`
export default DevotionalHymns
